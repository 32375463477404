import React from "react";
import "./Works.css";

const Works = () => {

  return (
    <div className="works" id="chrono">
      <div className="introWorks">
        <h2 className="titleH2">CHRONOLOGIE</h2>
        <p className="subtitle">Nunc nonummy metus. Donec elit libero, sodales nec, volutpat a, suscipit non, turpis.</p>
      </div>
      <ul className="chronologieSunda">
        <li class="list-li">
            <div className="first-line">
              <span className="round-first-line one"></span>
              <span className="line-separation"></span>
            </div>
            <div class="block-chronologie1">
              <span class="year y1">2019</span>
              <h4 class="chronoH4">Satisfaction client</h4>
              <p class="chronoP">Curabitur ullamcorper ultricies nisi. Praesent nonummy mi in odio. Donec mollis hendrerit risus.</p>
            </div>
        </li>
        <li class="list-li">
            <div className="first-line">
              <span className="round-first-line two"></span>
              <span className="line-separation"></span>
            </div>
            <div class="block-chronologie1">
              <span class="year y2">2020</span>
              <h4 class="chronoH4">Satisfaction client</h4>
              <p class="chronoP">Curabitur ullamcorper ultricies nisi. Praesent nonummy mi in odio. Donec mollis hendrerit risus.</p>
            </div>
        </li>
        <li class="list-li">
            <div className="first-line">
              <span className="round-first-line three"></span>
              <span className="line-separation"></span>
            </div>
            <div class="block-chronologie1">
              <span class="year y3">2021</span>
              <h4 class="chronoH4">Satisfaction client</h4>
              <p class="chronoP">Curabitur ullamcorper ultricies nisi. Praesent nonummy mi in odio. Donec mollis hendrerit risus.</p>
            </div>
        </li>
        <li class="list-li">
            <div className="first-line">
              <span className="round-first-line four"></span>
              <span className="line-separation"></span>
            </div>
            <div class="block-chronologie1">
              <span class="year y4">2022</span>
              <h4 class="chronoH4">Satisfaction client</h4>
              <p class="chronoP">Curabitur ullamcorper ultricies nisi. Praesent nonummy mi in odio. Donec mollis hendrerit risus.</p>
            </div>
        </li>
      </ul>
    </div>
  );
};

export default Works;
