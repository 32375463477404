import React from "react";
import "./Navbar.css";
import Sunda from "../../img/logo-sunda.png";
import { Link } from "react-scroll";
const navbar = () => {
  return (
      <div class="header_menu">
          <div className="n-wrapper" id="Navbar">
        {/* left */}
        <div className="n-left">
          <div className="n-name"><img src={Sunda} alt="logo sunda" height="50px" /></div>
        </div>
        {/* right */}
        <div className="n-right">
          <div className="n-list">
            <ul style={{ listStyleType: "none" }}>
              <li>
                <Link activeClass="active" to="" spy={true} smooth={true}>
                  Pourquoi Sunda ?
                </Link>
              </li>
              <li>
                <Link to="" spy={true} smooth={true}>
                  Fonctionnalités
                </Link>
              </li>
              <li>
                <Link to="" spy={true} smooth={true}>
                  Tarifs
                </Link>
              </li>
              <li>
                <Link to="" spy={true} smooth={true}>
                  Temoignages
                </Link>
              </li>
            </ul>
          </div>
          <Link to="" spy={true} smooth={true}>
          <button className="button n-button">DEMO</button>
          </Link>
        </div>
      </div>
  
      </div>
     );
};

export default navbar;
