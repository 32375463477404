import React from "react";
import "./Card.css";

const Card = ({icone, heading, detail, widthCard}) => {
  const parentCardClass = 'card '+ widthCard
  return (
    <div className={parentCardClass}> 
      <span class={icone}></span>
      <span>{heading}</span>
      <span>{detail}</span>
      <button className="button-card">En savoir plus</button>
    </div>
  );
};

export default Card;
