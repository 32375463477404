import React from "react";
import "./Marketing.css";
const Marketing = () => {

  return (
    <div className="marketing" id='marketing'>
        <div className="intro_marketing">
            <h2 className="title_marketing">SERVICES INCLUS</h2>
            <p className="subtitle_marketing">Nunc nonummy metus. Donec elit libero, sodales nec, volutpat a, suscipit non, turpis.</p>
        </div>
        
        <div className="service_marketing">
            <div className="sv_element_grid">

              <div className="element_sv">
                  <span className="ico_marketing ico_stats"></span>
                  <h5 class="sv_titleH5">Temps réel et données</h5>
                  <p className="sv_p">Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  <br/>Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.</p>
              </div>
              <div className="element_sv">
                  <span className="ico_marketing ico_realtime"></span>
                  <h5 class="sv_titleH5">Temps réel et données</h5>
                  <p className="sv_p">Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  <br/>Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.</p>
              </div>
              <div className="element_sv">
                  <span className="ico_marketing ico_checklist"></span>
                  <h5 class="sv_titleH5">Temps réel et données</h5>
                  <p className="sv_p">Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  <br/> Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.</p>
              </div>
              <div className="element_sv">
                  <span className="ico_marketing ico_report"></span>
                  <h5 class="sv_titleH5">Temps réel et données</h5>
                  <p className="sv_p">Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  <br/>Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.</p>
              </div>
              <div className="element_sv">
                  <span className="ico_marketing ico_file"></span>
                  <h5 class="sv_titleH5">Temps réel et données</h5>
                  <p className="sv_p">Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  <br/>Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.</p>
              </div>
              <div className="element_sv">
                  <span className="ico_marketing ico_stats"></span>
                  <h5 class="sv_titleH5">Temps réel et données</h5>
                  <p className="sv_p">Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  <br/>Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.</p>
              </div>

            </div>   
        </div>
     </div>
  );
};

export default Marketing;
