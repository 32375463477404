import React from "react";
import "./Services.css";
import Card from "../Card/Card";


const Services = () => {


  return (
    <div className="services" id="services">
 
      <div className="cards">
        {/* first card */}
      
          <Card
            widthCard ="normal_card"
            icone="icon-sunda-email "
            heading={"CONTACTS"}
            detail={"Nunc nonummy metus. Donec elit libero"}
          />
   
        {/* second card */}
       
          <Card
            widthCard ="large_card"
            icone="icon-sunda-marketing "
            heading={"COMPTE RENDU"}
            detail={"Nunc nonummy metus. Donec elit libero"}
          />
        
        {/* 3rd */}
       
          <Card
            widthCard ="ultra_large_card"
            icone="icon-sunda-analysis"
            heading={"MAILING"}
            detail={"Nunc nonummy metus. Donec elit libero"}
            color="rgba(252, 166, 31, 0.45)"
          />
            {/* 4th */}
       
          <Card
            widthCard ="large_card"
            icone="icon-sunda-search"
            heading={"DOCUMENTS"}
            detail={"Nunc nonummy metus. Donec elit libero"}
            color="rgba(252, 166, 31, 0.45)"
          />
              
         {/* 5th */}
       
        <Card
            widthCard ="normal_card"
            icone="icon-sunda-rapport"
            heading={"RAPPORT"}
            detail={"Nunc nonummy metus. Donec elit libero"}
            color="rgba(252, 166, 31, 0.45)"
          />
     
      </div>
    </div>
  );
};

export default Services;
