import React from "react";
import "./Footer.css";
import waveSunda from "../../img/wave-sunda.png";

const Footer = () => {
  return (
    <div className="footer">
      <img src={waveSunda} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <span>Copyright 2022 - Tous droits réservés</span>
      </div>
    </div>
  );
};

export default Footer;
