import React from "react";
import "./Experience.css";
const Experience = () => {

  return (
    <div className="experience" id='experience'>
        <div className="descr">
            <p className="subtitle_descr">A PROPOS</p>
            <h2 className="title_descr">Qui sommes nous ?</h2>
            <p className="text_descr">
              In hac habitasse platea dictumst.
               Aliquam lobortis. Lorem ipsum dolor sit amet, 
               consectetuer adipiscing elit. In dui magna, posuere eget,
               vestibulum et, tempor auctor, justo. Pellentesque habitant
               morbi tristique senectus et netus et malesuada fames ac turpis
               egestas.
            </p>
        </div>
        <div className="stats">
              <div className="block_stats">
                  <div className="elements_stats">
                      <div className="nb_projects">
                        <h2 className="number_stats">10</h2>
                        <h4 className="plus_stats">+</h4>
                      </div>
                      <span className="type_stats">Projets</span>
                  </div>
                      <hr className="border_stats" />
                      <p className="descr_stats">Praesent turpis. Praesent blandit laoreet nibh. Nunc nonummy metus.</p>
              </div>
              {/*2nd*/}
              <div className="block_stats">
                  <div className="elements_stats">
                      <div className="nb_projects">
                        <h2 className="number_stats">10</h2>
                        <h4 className="plus_stats">+</h4>
                      </div>
                      <span className="type_stats">Retour clients</span>
                  </div>
                      <hr className="border_stats" />
                      <p className="descr_stats">Praesent turpis. Praesent blandit laoreet nibh. Nunc nonummy metus.</p>
              </div>
              {/*3rd*/}
              <div className="block_stats">
                  <div className="elements_stats">
                      <div className="nb_projects">
                        <h2 className="number_stats">5</h2>
                        <h4 className="plus_stats">+</h4>
                      </div>
                      <span className="type_stats">Experience</span>
                  </div>
                      <hr className="border_stats" />
                      <p className="descr_stats">Praesent turpis. Praesent blandit laoreet nibh. Nunc nonummy metus.</p>
              </div>
         </div>
     </div>
  );
};

export default Experience;
