import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import Services from "./components/Services/Services";
import "./App.css";
import Experience from "./components/Experience/Experience";
import Marketing from "./components/Marketing/Marketing";
import Works from "./components/Works/Works";
import Portfolio from "./components/Portfolio/Portfolio";
import Testimonial from "./components/Testimonials/Testimonial";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {

  return (
    <div
      className="App"
    >
      <Navbar />
      <Intro />
      <Services />
      <Experience />
      <Marketing/>
      <Works />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
