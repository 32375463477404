import React, { useContext, useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import LinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import Envelope from "@iconscout/react-unicons/icons/uil-envelope";

const Contact = () => {

  const form = useRef();
  const [done, setDone] = useState(false)
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2mu5xtl",
        "template_m5udu2c",
        form.current,
        "VLwg1ltOWvnCYAiK_"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          form.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div class="contact">
        <div className="contact-form" id="contact">
          {/* left side copy and paste from work section */}
          <div className="w-left">
              <h2>CONTACTER - NOUS</h2>
              <span>Pour un démo ou autre informations contacter-nous</span>
              <div className="contact-icons">
                <LinkedIn color="white" size={"3rem"} />
                <Envelope color="white" size={"3rem"} />
              </div>
          </div>
          <div className="c-right">
            <form ref={form} onSubmit={sendEmail}>
              <input type="text" name="user_name" className="user"  placeholder="Nom"/>
              <input type="email" name="user_email" className="user" placeholder="Email"/>
              <textarea name="message" className="user" placeholder="Message"/>
              <input type="submit" value="Send" className="button"/>
              <span>{done && "Thanks for Contacting me"}</span>
              
            </form>
          </div>
        </div>
    </div>

  );
};

export default Contact;
