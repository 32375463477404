import React, { useContext } from "react";
import "./Intro.css";
import Vector4 from "../../img/Vector4.png";
import Vector3 from "../../img/Vector3.png";
import crmBox from "../../img/crm-box.png";
import glassesimoji from "../../img/glassesimoji.png";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          <span>CRM SUNDA</span>
          <span>Gestion de la Relation Client </span>
          <span>
           Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
          <button className="button">DECOUVREZ LE CRM SUNDA<i class=""></i></button>
        </Link>
        {/* social icons */}
        <div className="i-icons">
          <img src={LinkedIn} alt="" />
          <img src={Instagram} alt="" />
        </div>
      </div>
      {/* right image side */}
      <div className="i-right">
        <img src={Vector4} alt="" />
        <img src={Vector3} alt="" />
        <img src={crmBox} alt="" />
        {/* animation */}
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src={glassesimoji}
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={crown} text1="Web" text2="Application" />
        </motion.div>

        {/* animation */}
        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Best" text2="Tool" />
        </motion.div>

    
      </div>
    </div>
  );
};

export default Intro;
